<template>
  <div class="member-detail">
    <div>
      <div class="mb-2" style="color:#f00;"
        v-if="data.close_account == 2">此账号已被注销</div>
      <a-row>
        <a-col :span="5">
          <span>昵称：</span>
          <span>{{ data.nickname }}</span>
        </a-col>
        <template v-if="data.close_account != 2">
          <a-col :span="5">
            <span>微信：</span>
            <a-tooltip v-if="data.unionid" :title="data.wx_tips" :overlayStyle="{ maxWidth:'500px', whiteSpace: 'pre-line' }">
              <span>已绑定</span>
            </a-tooltip>
            <span v-else>未绑定</span>
          </a-col>
          <a-col :span="7">
            <span>绑定手机：</span>
            <span>{{ data.phone || "未绑定" }}</span>
            <a-button class="ml-4 p-0" type="link" size="small" 
              v-if="hasAuthPhone" @click="showEditPhone=true">修改</a-button>
          </a-col>
          <a-col :span="7">
            <span>生日：</span>
            <span>{{ data.birthday || "未设置" }}</span>
            <a-button class="ml-4 p-0" type="link" size="small" 
              v-if="hasAuthBirthday" @click="showEditBirthday=true">修改生日</a-button>
          </a-col>
        </template>
      </a-row>

      <a-row class="mt-3" v-if="data.close_account != 2">
        <!-- <a-col :span="6">
          <span>会员等级：</span>
          <span>{{ data.level }}</span>
        </a-col> -->
        <!-- <a-col :span="9">
          <span>成长值：</span>
          <span>{{ data.growth_value}}</span>
        </a-col> -->
        <a-col :span="5">
          <span>积分：</span>
          <span>{{ data.integral }}</span>
        </a-col>
        <a-col :span="12">
          <span>注册时间：</span>
          <span>{{ data.reg_time }}</span>
        </a-col>
        <a-col :span="7">
          <div class="flex" style="justify-content:flex-end;">
            <a-button
                v-if="hasAuthWriteOff"
                class="ml-4 p-0" style="color:#f00;" type="link" size="small" 
                @click="showWriteOff=true">注销</a-button>
          </div>
        </a-col>
      </a-row>


      <div v-if="isInBlackList" class="mt-2" style="background:#eee; padding:10px 20px;">
        <div>
          <span style="color:#f00;">此用户是黑名单用户</span>
          <a-button class="ml-4" type="link" size="small" 
            v-if="hasAuthClearBlack"
            @click="cancelBlack">点击解除</a-button>
        </div>
        <div class="flex align-center mt-2">
          <span>拉黑备注: </span>
          <div class="ml-3" style="flex:1;">
            <template v-if="hasAuthEditBlack">
                <a-textarea v-model="blackListRemarks"
                    :auto-size="{ minRows: 1, maxRows: 8 }"></a-textarea>
              <a-button class="mt-2" type="primary" size="small" @click="saveBlackRemarks()">修改备注</a-button>
            </template>
            <p v-else>{{ blackListRemarks }}</p>
          </div>
        </div>
      </div>
      <div v-else class="mt-2">
        <a-button type="link" style="padding:0;"
            v-if="hasAuthAddBlack"
            @click="addBlack">拉黑此账号</a-button>
      </div>
      
      <a-divider />

      <div class="flex justify-between">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane key="key_order" tab="用户的线上订单"></a-tab-pane>
        </a-tabs>
      </div>
      
      <div class="mt-3">
        <!-- 会员卡 -->
        <template v-if="activeKey == 'key_vip'">
          <div class="mb-3">
            <span>会员卡金额变更时间：</span>
            <a-range-picker style="width:230px" v-model="vipCardSearchForm.time" valueFormat="YYYY-MM-DD" />
          </div>
          <base-table :columnsData="vipCardColumnsList"
                      :tableData="vipCardTableData"
                      :getListFunc="getVipCardLogList"
                      :total="vipCardSearchForm.total"
                      :page.sync="vipCardSearchForm.page"
                      :pageSize.sync="vipCardSearchForm.page_count">
            <template #operation="{record}">
              <a-button type="link" v-if="record.order_no" @click="handlerOrderDetail(record.order_no)">订单详情</a-button>
            </template>
          </base-table>
        </template>
        
        <!-- 订单列表 -->
        <template v-if="activeKey == 'key_order'">
          <div class="mb-3">
            <span>下单日期：</span>
            <a-range-picker style="width:230px" v-model="orderSearchForm.order_add_time_arr" valueFormat="YYYY-MM-DD" />
          </div>
          <base-table :columnsData="orderColumnsList"
                      rowKey="order_no"
                      :tableData="orderTableData"
                      :getListFunc="getOrderList"
                      :total="orderSearchForm.total"
                      :page.sync="orderSearchForm.page"
                      :pageSize.sync="orderSearchForm.page_count">
            <template #order_no="{text}">
              <a-button class="p-0" type="link" @click="handlerOrderDetail(text)">{{text}}</a-button>
            </template>
            <template #order_status="{ text }">
              <!-- -2-已取消（旺店通需自行处理），-1-已取消，1-未支付，2-未审核，3-已分派，4-已完成） -->
              <a-tag class="mr-0"
                :color="text < 0 ? 'red' : (text < 3 ? '' : (text < 4 ? 'blue' : 'green')) "
              >{{ formatDetailOrderStatus(text) }}</a-tag>
            </template>
          </base-table>
        </template>
      </div>
    </div>

    <!-- 注销 -->
    <write-off v-if="showWriteOff" :show.sync="showWriteOff"
         :memberId="memberId"
         @ok="wirteOffComplete"></write-off>

    <!-- 加黑名单 -->
    <addblack-modal v-if="showAddBlack" :show.sync="showAddBlack"
         :memberId="memberId"
         @added="addBlackComplete"></addblack-modal>

    <!-- 修改生日 -->
    <edit-phone v-if="showEditPhone" :show.sync="showEditPhone"
        :memberId="memberId"
        @ok="initData"></edit-phone>
  </div>
</template>

<script>
import { formatDetailOrderStatus } from "@/utils/type";
import { checkAuth } from "@/utils/authData.js";
import {
  getMemberDetail,
  getUserBlackInfo,
  updateUserBlackInfo,
  pushOutUserBlack,
  getVipChangeLog 
} from "@/api/vip.js"
import {
  getAllOrderList
} from "@/api/customer.js"

import pageData from "./columns"
import writeOff from "./components/write-off.vue"
import addblackModal from "@/components/order/addblack-modal.vue"


import EditPhone from "./components/edit-phone.vue";

export default {
  components: {
    writeOff,
    addblackModal,
    EditPhone
  },
  props: {
    memberId: {
      type: [String, Number],
      default: "",
    },
    num: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      ...pageData,
      cardNo: "",
      formatDetailOrderStatus,
      requestID: "",
      data: {},

      hasAuthWriteOff:    checkAuth("member:close_account"),
      hasAuthAddBlack:    checkAuth("member_black_list:add"),
      hasAuthClearBlack:  checkAuth("member_black_list:cancel"),
      hasAuthEditBlack:   checkAuth("member_black_list:update"),

      hasAuthPhone:       checkAuth("member:update"),
      activeKey: "",
      // 会员卡
      vipCardSearchForm: {
        member_id: this.memberId,
        time: [],
        change_time_start: '',
        change_time_end: '',
        total: 0,
        page: 1,
        page_count: 20
      },
      vipCardTableData: [],

      // 订单
      orderSearchForm: {
        member_id: this.memberId,
        order_no: "",
        order_source: 0,
        order_status: 0,
        order_add_time_arr: [],
        order_add_time_start: "",
        order_add_time_end: "",
        consignee_recipient_name: "",
        consignee_recipient_phone: "",
        consignee_recipient_time_arr: [],
        consignee_recipient_time_start: "",
        consignee_recipient_time_end: "",
        consignee_address: "",
        order_type: 0,
        goods_type: 0,
        pay_flow_no: "",
        pay_mode: 0,

        total: 0,
        page: 1,
        page_count: 20
      },
      orderTableData: [],

      // 订单
      offlineSearchForm: {
        member_id: this.memberId,
        time: [],
        change_time_start: '',
        change_time_end: '',
        total: 0,
        page: 1,
        page_count: 20
      },
      offlineTableData: [],


      showEditPhone: false,
      showAddBlack: false,
      isInBlackList: false,
      blackListRemarks: "",

      showWriteOff: false,
    }
  },
  watch: {
    num () {
      this.initData()
    },
    'vipCardSearchForm.time' () {
      this.vipCardSearchForm.page = 1
      this.getVipCardLogList()
    },
    'orderSearchForm.order_add_time_arr' () {
      this.orderSearchForm.page = 1
      this.getOrderList()
    }
  },
  created () {
    if(this.$route.query.member_id){ // 如果是新开页：参数member_id
      this.memberId = this.$route.query.member_id
      this.vipCardSearchForm.member_id = this.memberId
      this.orderSearchForm.member_id   = this.memberId
      this.offlineSearchForm.member_id = this.memberId
    }else if(this.$route.query.card_no){ // 如果是新开页：参数会员卡号
      this.cardNo = this.$route.query.card_no
    }
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getMemberDetail({
        member_id: this.memberId,
        card_no: this.cardNo,
      })
      if (code === 0) {
        if(data.unionid){
          data.wx_tips = "openid："+data.openid+"\n unionid："+data.unionid
        }
        this.data = data
        this.$emit('getDetail', this.data)
        if(!this.memberId){
          this.memberId = data.member_id
          this.vipCardSearchForm.member_id = this.memberId
          this.orderSearchForm.member_id   = this.memberId
          this.offlineSearchForm.member_id = this.memberId
        }
        this.initBlackList()
        this.handlerTypeChange("key_order")
      }
    },

    handlerTypeChange (key) {
      this.activeKey = key

      if(key == "key_vip"){
        this.getVipCardLogList()
      }else if(key == "key_order"){
        this.getOrderList()
      }else if(key == "key_offline"){
        // this.getOfflineList()
      }
    },

    async initBlackList(){
      const { data, code } = await getUserBlackInfo({ 
        member_id: this.memberId 
      })
      if(code == 0){
        this.isInBlackList = data.blacklist_status==2
        this.blackListRemarks = data.blacklist_remarks || ""
      }
    },
    // 加黑名单
    async addBlack() {
      this.showAddBlack = true
    },
    addBlackComplete(e){
      this.showAddBlack = false
      this.isInBlackList = true
      this.blackListRemarks = e.remarks
    },

    // 解除黑名单
    async cancelBlack(){
      pushOutUserBlack({
        member_id: this.memberId
      }).then(res => {
        if (res.code === 0) {
          this.isInBlackList = false
          this.blackListRemarks = ""
          this.$message.success("已解除")
        }
      })
    },
    // 修改黑名单
    async saveBlackRemarks(){
      updateUserBlackInfo({
        member_id: this.memberId,
        blacklist_remarks: this.blackListRemarks
      }).then(res => {
        if (res.code === 0) {
          this.$message.success("已保存")
        }
      })
    },

    // 注销
    wirteOffComplete() {
      this.showWriteOff = false;
      this.initData()
    },

    async getVipCardLogList () {
      const params = this.vipCardSearchForm
      if (this.vipCardSearchForm.time.length > 0) {
        params.change_time_start = this.vipCardSearchForm.time[0]
        params.change_time_end = this.vipCardSearchForm.time[1]
      }

      const { data, code } = await getVipChangeLog(params)
      if (code === 0) {
        this.vipCardTableData = data.list.map((el, i) => {
          el.key = i + el.change_time
          return el
        })
        this.vipCardSearchForm.total = data.total_count
      }
    },
    async getOrderList () {
      const params = this.orderSearchForm
      params.order_add_time_start = ""
      params.order_add_time_end = ""
      params.consignee_recipient_time_start = ""
      params.consignee_recipient_time_end = ""
      if (params.order_add_time_arr.length > 0) {
        params.order_add_time_start = params.order_add_time_arr[0]
        params.order_add_time_end = params.order_add_time_arr[1]
      }
      if (params.consignee_recipient_time_arr.length > 0) {
        params.consignee_recipient_time_start = params.consignee_recipient_time_arr[0]
        params.consignee_recipient_time_end = params.consignee_recipient_time_arr[1]
      }
      const { data, code } = await getAllOrderList(params)
      if (code === 0) {
        data.list.map(el=>{
          el.time = this.formatTime(el.consignee_recipient_time_start) + "~" +
                    this.formatTime(el.consignee_recipient_time_end.split(' ')[1])
          return el
        })
        this.orderTableData = data.list
        this.orderSearchForm.total = data.total_count
      }
    },
    handlerOrderDetail (order_no) {
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: order_no },
      });
      window.open(newPage.href, "_blank");
    },
    formatTime(time){
      if(/\d{2}:\d{2}:\d{2}/.test(time)){
        return time.slice(0, -3)
      }
    }
  },
}
</script>

<style lang="less" scoped>
.order-detail-container {
  // margin: 20px;
  padding: 20px 20px 50px 20px;
  background: #ffffff;
}
.shop-table-class {
  // margin-left: -10px;
}
.order-detail-title {
  width: 70px;
  text-align: right;
  display: inline-block;
}
</style>
