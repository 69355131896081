export const rowKey_vip = "order_no";
export const rowKey_order = "order_no";

import {
  formatOrderSource,
} from "@/utils/type";

export const columns = [
  {
    title: "ID",
    align: "center",
    dataIndex: "member_id",
    width: 100,
  },
  {
    title: "昵称",
    align: "center",
    dataIndex: "nickname",
    width: "15%",
    slots: {
      customRender: "nickname",
    },
  },
  {
    title: "绑定手机",
    align: "center",
    dataIndex: "phone",
    width: 160,
  },
  // {
  //   title: "会员卡开卡地",
  //   align: "center",
  //   dataIndex: "open_region",
  // },
  // {
  //   title: "会员卡开卡时间",
  //   align: "center",
  //   dataIndex: "open_card_time",
  //   width: "19%",
  // },
  {
    title: "会员积分",
    align: "center",
    dataIndex: "integral",
    width: "9%",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 100,
    slots: {
      customRender: "operation",
    },
  },
];

export const vipCardColumnsList = [
  {
    title: "变更类型",
    dataIndex: "change_type",
    align: "center",
  },
  {
    title: "变更时间",
    dataIndex: "change_time",
    align: "center",
  },
  {
    title: "订单交付方",
    dataIndex: "owner",
    align: "center",
  },
  {
    title: "变更金额",
    dataIndex: "recharge_amount",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    slots: {
      customRender: "operation",
    },
  },
]

export const orderColumnsList = [
  {
    title: "订单ID",
    dataIndex: "order_no",
    align: "center",
    width: 170,
    slots: { customRender: "order_no" },
  },
  {
    title: "订单来源",
    dataIndex: "order_source",
    align: "center",
    width: "12%",
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatOrderSource(val),
  },
  {
    title: "下单时间",
    dataIndex: "order_add_time",
    align: "center",
    width: "17%",
  },
  {
    title: "自提/收货时间",
    dataIndex: "time",
    align: "center",
    width: "20%",
    // slots: {
    //   customRender: "time",
    // },
  },
  {
    title: "完成时间",
    dataIndex: "order_finish_time",
    align: "center",
    width: "17%",
  },
  {
    title: "负责网点",
    dataIndex: "actual_sale_ascription_outlets",
    align: "center",
    width: "14%",
  },
  {
    title: "订单状态",
    dataIndex: "order_status",
    align: "center",
    width: 80,
    slots: {
      customRender: "order_status",
    },
  },
  {
    title: "调度人",
    dataIndex: "dispatch_staff",
    align: "center",
    width: "12%",
  }
]

export default {
  columns,

  rowKey_vip,
  rowKey_order,
  vipCardColumnsList,
  orderColumnsList,
};
